<template>
  <modal-wrapper
    :id="id"
    hide-footer
    hide-header
    size="lg"
  >
    <template #title>
      Cambio Stato
    </template>
    <template #body>
      <div class="px-3" v-if="needComment">
        <h5 class="heading--dark">Specificare la motivazione:</h5>
        <editor
          :api-key="apiKey"
          @onChange="updateComment($event)"
          :init="config"
        />
      </div>
      <div class="px-3" v-else>
        <h5 class="heading--dark">Selezionare una risposta per la motivazione</h5>
        <v-select
          id="responseType"
          label="value"
          placeholder="Selezionare una voce"
          v-model="responseTypeModel"
          :options="responseTypes"
          :reduce="item => item.key"
        >
        </v-select>
      </div>
      <div class="mt-3 pt-3 border-top d-flex">
        <b-btn size="sm" variant="warning" @click="closeModal">CHIUDI</b-btn>
        <b-btn
          size="sm"
          variant="success"
          @click="saveStatus"
          class="ms-auto"
        >
          SALVA
        </b-btn>
      </div>
    </template>
  </modal-wrapper>
</template>

<script>
import { tinyMceAPIKey } from '@/utils/utilities';
import { editorConfig } from '@/utils/formatterHelper';
import { isNotEmpty, isPresent } from '@/utils/validators';

const Editor = () => import('@tinymce/tinymce-vue');
const ModalWrapper = () => import('@/components/helpers/ModalWrapper.vue');

export default {
  name: 'ModalStatusChange',
  components: {
    ModalWrapper, Editor,
  },
  props: {
    id: String,
    onModalHide: Function,
    state: String,
    reportName: String,
    userReportId: String,
    responseContext: String,
    isOwner: Boolean,
  },
  data() {
    return {
      closeComment: '',
      apiKey: tinyMceAPIKey,
      config: { ...editorConfig, height: 300 },
      responseTypeModel: null,
    };
  },
  watch: {
    responseContext(val, oldVal) {
      console.log('watch responseContext', val, oldVal);
      if (val !== oldVal && val) this.fetchResponse();
    },
  },
  computed: {
    needComment() {
      return !isPresent(this.responseContext);
    },
    responseTypes() {
      if (isNotEmpty(this.responseContext)) {
        console.log('this.responseContext', this.responseContext);
        return this.$store.getters['tableUtils/getTableList'](this.responseContext) || [];
      }
      return [];
    },
  },
  created() {
    console.log(' created this.responseContext', this.responseContext);
    this.fetchResponse();
  },
  mounted() {
    console.log(' mounted this.responseContext', this.responseContext);
    this.resetData();
  },
  methods: {
    fetchResponse() {
      console.log('this.responseContext', this.responseContext);
      if (!isPresent(this.responseContext)) return;
      if (!this.$store.getters['tableUtils/hasTableList'](this.responseContext)) {
        this.$store.dispatch('tableUtils/loadTableList', { tableName: this.responseContext, language: 'it' }).then(() => {
          console.log(`loaded response ${this.responseContext}`);
        });
      }
    },
    resetData() {
      this.closeComment = '';
      this.responseTypeModel = null;
      this.responseTypes = null;
      this.fetchResponse();
    },
    closeModal() {
      if (this.tinymce) this.tinymce.remove('#editor');
      this.$bvModal.hide(this.id);
    },
    updateComment(e) {
      // console.log('closeComment value', e, e.target.getContent());
      this.closeComment = e.target.getContent();
    },
    saveStatus() {
      if (this.isOwner) {
        this.$store.dispatch('score/updateStatusReport', {
          reportName: this.reportName,
          userReportId: this.userReportId,
          status: this.state,
          closeComment: this.closeComment,
          responseCodeId: this.responseContext,
        })
          .then(() => {
            // console.log('saved!!!', this.reportName, this.userReportId, this.state, this.closeComment);
            this.closeModal();
            this.$router.replace('/user-report-history');
          },
          (error) => {
            console.log('save explosion', error);
          });
      } else {
        this.$store.dispatch('score/litigationStatusReport', {
          reportName: this.reportName,
          userReportId: this.userReportId,
          responseCodeId: this.responseContext,
        })
          .then(() => {
            // console.log('saved!!!', this.reportName, this.userReportId, this.state, this.closeComment);
            this.closeModal();
            this.$router.replace('/user-report-history');
          },
          (error) => {
            console.log('save explosion', error);
          });
      }
    },
  },
};
</script>

<style scoped>

</style>
